import React, {useState, useEffect} from 'react'
import NavBar from "./navbar.js"
import Footer from "./footer.js"
import scrollTo from 'gatsby-plugin-smoothscroll';
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import "../styles/bootstrap.scss"


export default function Layout({children}) {
    const [scroll, setScroll] = useState(false)
    const [navbar, setNavbar] = useState(false)
    
    useEffect(() => {
        if( typeof window !== `undefined`) {
            window.onscroll = () => {
                setScroll(window.scrollY > 300 ? true : false)
                setNavbar(window.scrollY > 0 ? true : false)
            }
        }
    })

    return (
        <div id="top">
            <NavBar navScroll={navbar}/>
            <main>
                {children}
            </main>

            <Footer />
            <button style={{display: (scroll ? "initial" : "none")}} className="scrollTop" onClick={() => scrollTo('#top')}>
                <FontAwesomeIcon
                style={{"fontSize": "30px", "color": "rgba(255,255,255,0.70)"}}
                icon={faAngleUp}/>
            </button>
        </div>
    )
}
