import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import GlobalJSON from "../content/global.json"
import ContactJSON from "../content/contact.json"
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome'
import { faFacebookF, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt, faEnvelope, faPhone  } from '@fortawesome/free-solid-svg-icons'

export default function Footer() {
    return (
        <div id="footer">
            <Container fluid className="text-center my-4">
                <Row className="justify-content-center">
                    <Col lg={3} className="mb-2">
                        <Link to="/">
                            <img style={{width: '100%', height: '100px'}} src="/logos/trabus_logo_short_white.svg" alt="Trabus logo"/>
                        </Link>
                        <p className="logo-text">{GlobalJSON.slogan}</p>
                    </Col>
                    <Col lg={2} className="mb-4">
                        <h6 className="my-3" style={{color: 'white', fontWeight: 500}}>COMPANY</h6>
                            <div className="d-flex flex-column">
                                <Link to="/our-history" className="mb-1">About Us</Link>
                                <Link to="/careers" className="mb-1">Careers</Link>
                                <Link to="/contact" className="mb-1">Contact Us</Link>
                                <Link to="/privacy-policy" className="mb-1">Privacy Policy</Link>
                            </div>
                    </Col>
                    <Col lg={3} className="mb-4">
                        <h6 className="my-3" style={{color: 'white', fontWeight: 500}}>SOCIAL MEDIA</h6>
                        <div className="d-flex justify-content-center">
                            <a className="d-flex mx-1 justify-content-center align-items-center social-icon"
                               target="_blank" rel="noopener noreferrer"
                               href="https://www.facebook.com/Trabus-Technologies-143543429114409/">
                                <FontAwesomeIcon style={{fontSize: "20px", color: "white"}} icon={faFacebookF} />
                            </a>
                            <a className="d-flex mx-1 justify-content-center align-items-center social-icon"
                               target="_blank" rel="noopener noreferrer"
                               href="https://www.linkedin.com/company/trabus-technologies">
                                <FontAwesomeIcon style={{fontSize: "20px", color: "white"}} icon={faLinkedinIn} />
                            </a>
                            <a className="d-flex mx-1 justify-content-center align-items-center social-icon"
                               target="_blank" rel="noopener noreferrer"
                               href="https://www.youtube.com/channel/UCttreg0QOabPfbfYPh3AHKA">
                                <FontAwesomeIcon style={{fontSize: "20px", color: "white"}} icon={faYoutube} />
                            </a>
                            <a className="d-flex mx-1 justify-content-center align-items-center social-icon"
                               target="_blank" rel="noopener noreferrer"
                               href="https://twitter.com/Trabus_Tech">
                                <FontAwesomeIcon style={{fontSize: "20px", color: "white"}} icon={faTwitter} />
                            </a>
                        </div>
                    </Col>
                    <Col lg={2} className="mb-4">
                        <h6 className="my-3" style={{color: 'white', fontWeight: 500}}>CONTACT US</h6>
                        <div className="d-flex flex-column">
                            <span className="mb-3">
                                <FontAwesomeIcon className="mr-3" style={{color: "white"}} icon={faMapMarkerAlt} />
                                <a target="_blank" rel="noreferrer" href="https://goo.gl/maps/woHAnZN8uAVA4aE68" style={{color: "white"}}>{ContactJSON.address}</a>
                            </span>
                            <span className="mb-3">
                                <FontAwesomeIcon className="mr-3" style={{color: "white"}} icon={faEnvelope} />
                                <span style={{color: "white"}}>{ContactJSON.email}</span>
                            </span>
                            <span className="mb-3">
                                <FontAwesomeIcon className="mr-3" style={{color: "white"}} icon={faPhone} />
                                <a href={`tel:`+ContactJSON.phone} style={{color: "white"}}>{ContactJSON.phone}</a>
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="w-100 text-center">
                <small className="copyright">Copyright &copy; {new Date().getFullYear()} TRABUS Technologies. All Rights Reserved.</small>
            </div>
        </div>
    )
}
